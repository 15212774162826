import React, {useState, useEffect} from 'react';
import ModalComponent from "../atoms/ModalComponent";
import {Button} from "../atoms/Button";
import TextInputWrapper from "../atoms/TextInputWrapper";
import CheckboxItem from "../atoms/CheckboxItem";
import {toast} from "react-toastify";
import {TOAST_OPTIONS} from "utils/consts";
import TooltipComponent from "components/atoms/TooltipComponent";
import SvgLink from "components/icons/SvgLink";
import SvgCopy from "components/icons/SvgCopy";
import SvgCheckCircle from "components/icons/SvgCheckCircle";
import SvgHelp from "components/icons/SvgHelp";
import { fetchGuestShortLinks } from 'api/hosts';
import { connect } from 'react-redux';
import { quickInviteGuest } from 'api/guests';

const mapStateToProps = (state) => {
    return {
        eventId: state.userReducer.currentEventId,
        mobile: state.userReducer.mobile,
        guestId: state.userReducer.authSession?.user?.id,
    }
}

function InviteGuestModal(props) {
    const [description, setDescription] = useState("");
    const [copyState, setCopyState] = useState('link'); // 'link', 'hover', 'copied'
    const [shortLinks, setShortLinks] = useState([]);

    useEffect(() => {
        fetchGuestShortLinks(props.eventId, props.guestId)
        .then(shortLinks => {
          setShortLinks(shortLinks);
        }) 
    }, [])

    const handleCopy = async () => {
        if (props.mobile) {
            navigator.share({
                url: shortLinks?.[0]?.short_link || "",
                text: `You've been invited to an event on Opassity! Click here to accept your spot.`
            })
        } else {
            navigator.clipboard.writeText(shortLinks?.[0]?.short_link || "").then(() => {
                setCopyState('copied');
                toast.success("Invite link copied to clipboard!", TOAST_OPTIONS);
                setTimeout(() => setCopyState('link'), 5000); // Reset after 5 seconds
            }).catch(error => {
                // Using TOAST_OPTIONS for error notification
                toast.error("Failed to copy invite link.", TOAST_OPTIONS);
            });
        }
    }

    const CurrentIcon = () => {
        switch (copyState) {
            case 'hover':
                return <SvgCopy/>;
            case 'copied':
                return <SvgCheckCircle/>;
            default:
                return <SvgLink/>;
        }
    }


    const handleMouseEnter = () => {
        if (copyState !== 'copied') { // Don't switch to hover icon if already copied
            setCopyState('hover');
        }
    }

    const handleMouseLeave = () => {
        if (copyState !== 'copied') {
            setCopyState('link');
        }
    }

    const handleAddToInviteList = async () => {
        if (!!props.submit) {
            props.submit();
        } else {
            quickInviteGuest(props.eventId, props.hostId, props.phone, props.name, props.email, true);
        }
        handleCopy();
    }

    useEffect(() => {
        switch (props.reason) {
            case "NoAccount":
                setDescription("This phone number is not registered on Opassity. Due to recent regulations, we cannot send messages to users who are not registered on the platform. Copy this link to invite them directly.")
                break;
            case "AlreadyInvited":
                setDescription("This person has already been invited to this event.")
                break;
            default:
                props.close();
        }
    }, [props.reason])

    return (
        <ModalComponent
            isOpen={props.isOpen}
            size={'sm'}
            header={'Send an invite'}
            description={description}
            toggle={props.close}
            FooterComponent={
                <div className='flex flex-row gap-3 w-full'>
                    <Button
                        variant="secondary"
                        onClick={() => props.close()}
                        className="flex w-full"
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => handleAddToInviteList()}
                        variant='default'
                        className="flex w-full"
                    >
                        Copy and send
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col gap-3'>
                <TooltipComponent
                    ToggleComponent={
                        <div className='flex flex-row items-center group gap-2'>
                            <p className='text-lg font-bold'>
                                Your Invite
                            </p>
                            <SvgHelp className={'w-4 h-4 opacity-50 group-hover:opacity-100 group-hover:text-primary'}/>
                        </div>
                    }
                    hover
                    placement={'bottom-start'}
                    label={'Anyone will be able to request to attend with this link. You\'ll see guests who fill out the link appear in your Approvals tab.'}
                />
                <button
                    className={`
                    ${copyState === 'copied' ? 'bg-green-50 border-green-500 text-green-500' : 'bg-primary-50 hover:bg-primary-100 cursor-pointer border-primary/50 text-primary'}
                    rounded-xl border-[1.5px] p-3 flex flex-row items-center gap-3`}
                    onClick={handleCopy}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <CurrentIcon/>
                    <p className='text-md font-bold text-left flex-1 truncate'>
                        {shortLinks?.[0]?.short_link}
                    </p>
                </button>
                <p className='text-xs text-gray-500'>
                Link expires 2h before start of the event
                </p>
            </div>
        </ModalComponent>
    );
}

export default connect(mapStateToProps, null)(InviteGuestModal);
