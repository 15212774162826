import React, {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import GuestEventPageHeader from "../../components/guest_view/GuestEventPageHeader";
import {Button} from "../../components/atoms/Button";
import SvgCheckCircle from "../../components/icons/SvgCheckCircle";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import RequestToAttendModal from "../../components/modals/RequestToAttendModal";
import InvitePlusOneModal from "../../components/modals/InvitePlusOneModal";
import {connect} from 'react-redux';
import {getEventById} from '../../api/events';
import parsePhoneNumber from 'libphonenumber-js';
import {createGuest, setGuestStatus, requestInvite} from "api/guests";
import QrCodeModal from "../../components/modals/QrCodeModal";
import GuestAcceptorDeclineInviteView from "../../components/guest_view/GuestAcceptorDeclineInviteView";
import ListZeroState from "../../components/components/ListZeroState";
import check_back_image from "../../assets/img/casual-life-3d-pink-alarm-clock-on-ground.png";
import request_needed_image from '../../assets/img/casual-life-3d-paper-airplane-1.png'
import not_going_image from '../../assets/img/casual-life-3d-white-puzzle-piece.png'
import AcceptedInviteSuccessModal from "../../components/modals/AcceptedInviteSuccessModal";
import {addBlockedHosts} from "api/blocked_hosts";
import SvgCalendar from "../../components/icons/SvgCalendar";
import {DateTime} from "luxon";
import {dateOrdinal} from "../../utils/helpers";
import SvgClock from "../../components/icons/SvgClock";
import {fetchGuestShortLinks} from 'api/hosts';
import GroupTag from "../../components/atoms/GroupTag";

const mapStateToProps = (state) => {
    return {
        authSession: state.userReducer.authSession,
        currentEventId: state.userReducer.currentEventId,
        mobile: state.userReducer.mobile,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentEventId: (currentEventId) => {
            dispatch({
                type: "SET_CURRENT_EVENT_ID",
                currentEventId
            })
        },
    }
}

const CopyLinkButton = ({mobile, shortLink}) => {
    const [copyState, setCopyState] = useState('link');

    const handleCopy = () => {
        navigator.clipboard.writeText(shortLink).then(() => {
            setCopyState('copied')
            setTimeout(() => setCopyState("link"), 2000);
        })
    }

    return (
        <button
            onClick={handleCopy}
            className={`
        ${copyState === 'copied' ? 'bg-green-50 border-green-500 text-green-500' : 'bg-primary-50 hover:bg-primary-100 cursor-pointer border-primary/50 text-primary'}
        rounded-xl border-[1.5px] p-3 flex flex-row items-center gap-3
      `}
        >
            {copyState === 'copied' ? 'Copied!' : shortLink}
        </button>
    )
}


function GuestEventPage(props) {
    useEffect(() => {
        setEvent(props.event);
    }, [])
    
    const {eventId} = useParams();
    const [searchParams] = useSearchParams();

    const profileId = props.authSession.user.id;
    const navigate = useNavigate();
    const [event, setEvent] = useState({});
    const [eventLoading, setEventLoading] = useState(true);
    const [qrCodeVisible, setQrCodeVisible] = useState(false);
    const [isAcceptedSuccessModalVisible, setIsAcceptedSuccessModalVisible] = useState(false)

    const [confirmRejectInviteModalOpen, setConfirmRejectInviteModalOpen] = useState(false)
    const [confirmLeaveGuestListModalOpen, setConfirmLeaveGuestListModalOpen] = useState(false)
    const [isRequestToAttendModalOpen, setIsRequestToAttendModalOpen] = useState(false)
    const [isInvitePlusOneModalOpen, setIsInvitePlusOneModalOpen] = useState(false)
    const [shortLink, setShortLink] = useState('');

    // isAuthenticated is used to change supporting copy whether user is logged in already or not
    const isAuthenticated = true
    const guests = event?.guests || [];
    const userGuestObject = guests.filter(guest => guest.profile_id === profileId)[0];

    const myParentGuestObject = guests.filter(guest => guest.profile_id === userGuestObject?.parent_guest)[0];

    // isDirectInvite true if this is a direct invite. false if it is a generic event link OR if it's a guest +1, in these cases we should show 'request to attend'
    const myHostId = userGuestObject?.host_id || searchParams.get("hostId", null);
    const hosts = event?.hosts || [];
    const myHostObject = hosts.filter(host => host.profile_id === myHostId)[0];

    const isGuestPlusOne = !userGuestObject?.is_primary_guest
    //this is used to show pending state if the user has accepted a direct invite or requested to attend w general link
    const hasAcceptedInviteOrRequested = userGuestObject?.status === "Pending Host" || userGuestObject?.status === "Pending Organizer" || userGuestObject?.status === "Rejected";
    const isApprovedToAttend = userGuestObject?.status === "Approved";
    const isAttending = userGuestObject?.status === "Attending";
    const isNotAttending = userGuestObject?.status === "Not Attending";

    //this turns true if they are approved and within 2h of eve
    const downloadAvailable = DateTime.fromISO(props.event.start_timestamp).minus({hours: 2}) <= DateTime.now();

    const invitedUsers = event?.invited_users || [];
    const myInvitedUsers = invitedUsers.filter(user => user.invited_by === profileId).map(user => ({
        contact: `${user.name} - ${parsePhoneNumber(`+1${user.phone_number}`)?.formatNational() || user.email}`,
        status: "Not Attending"
    }));
    const myInvitedGuests = guests.filter(guest => guest.parent_guest === profileId).map(guest => ({
        contact: `${guest.profile.first_name} ${guest.profile.last_name} - ${parsePhoneNumber(`+1${guest.profile.phone_number}`)?.formatNational() || guest.profile.email}`,
        status: guest.status === "Attending" ? "Attending" : "Not Attending",
    }));
    const guestInvites = myInvitedGuests.concat(myInvitedUsers);

    const getEvent = async () => {
        if (!props.authSession) return;

        props.setCurrentEventId(eventId);
        try {
            setEventLoading(true);
            const event = await getEventById(eventId);
            setEvent(event);
            setEventLoading(false);
        } catch (err) {
            console.log(err);
            navigate("/")
        }
    }

    useEffect(() => {
        fetchGuestShortLinks(eventId, profileId).then(shortLinks => `${window.location.origin}/invite/${setShortLink(shortLinks[0].short_link)}`)
    }, [])

    const openConfirmRejectInviteModal = useCallback(() => {
        setConfirmRejectInviteModalOpen(true)
    }, [])

    const closeConfirmRejectInviteModal = useCallback(() => {
        setConfirmRejectInviteModalOpen(false)
    }, [])

    const openConfirmLeaveGuestListModal = useCallback(() => {
        setConfirmLeaveGuestListModalOpen(true)
    }, [])

    const closeConfirmLeaveGuestListModal = useCallback(() => {
        setConfirmLeaveGuestListModalOpen(false)
    }, [])


    const openRequestToAttendModal = useCallback(() => {
        setIsRequestToAttendModalOpen(true)
    }, [])

    const closeRequestToAttendModal = useCallback(() => {
        setIsRequestToAttendModalOpen(false)
    }, [])

    const openInvitePlusOneModal = useCallback(() => {
        setIsInvitePlusOneModalOpen(true)
    }, [])

    const closeInvitePlusOneModal = useCallback(() => {
        setIsInvitePlusOneModalOpen(false)
    }, [])

    const updateGuestStatus = async (status) => {
        await setGuestStatus(eventId, profileId, status, true); // Ignore redux on guest platform
        if (status === 'Attending') {
            setIsAcceptedSuccessModalVisible(true)
        } else {
            closeSuccessModal()
        }
    }

    const declineAndBlock = async () => {
        await addBlockedHosts(profileId, myHostId);
        updateGuestStatus('Not Attending')
    }

    const closeSuccessModal = () => {
        setIsAcceptedSuccessModalVisible(false)
        window.location.reload();
    }

    const toggleQrCodeVisible = () => {
        setQrCodeVisible(!qrCodeVisible)
    }

    const requestAccess = async (justification) => {
        if (!myHostId && !userGuestObject) {
            await createGuest(eventId, profileId, justification);
        } else {
            await requestInvite(eventId, myHostId, profileId, !!myHostId ? "Pending Host" : "Pending Organizer", justification);
        }
        window.location.reload();
    }

    return (
        <div className='flex flex-col flex-1 w-full p-5 relative'>
            <div className='w-full md:w-[740px] mx-auto flex flex-col gap-8 pb-20'>

                {/*Desktop only, mobile component at bottom*/}
                <div className='mt-5 hidden md:flex'>
                    <GuestEventPageHeader event={event} hideLocation={!isApprovedToAttend && !isAttending}/>
                </div>

                <div className='flex flex-col gap-3'>
                    <div
                        className='flex flex-col md:flex-row items-center justify-between border-b border-gray-200 pb-5'>
                        {/*Mobile only*/}
                        <p className='text-3xl font-bold flex md:hidden mt-3 text-center'>
                            {event.title}
                        </p>
                        {/*Desktop only*/}
                        <p className='text-2xl font-bold hidden md:flex'>
                            My Status
                        </p>
                        {!!isAttending ?
                            <div className='flex flex-row items-center gap-1 text-green-500'>
                                <SvgCheckCircle className={'w-4 h-4'}/>
                                <p className='font-bold'>
                                    Attending
                                </p>
                            </div>
                            :
                            isApprovedToAttend ?
                                <div className='flex flex-row items-center gap-1 text-gray-500'>
                                    <SvgCheckCircle className={'w-4 h-4'}/>
                                    <p className='font-bold'>
                                        Approved
                                    </p>
                                </div>
                                :
                                hasAcceptedInviteOrRequested ?
                                    <div className='flex flex-row items-center gap-1 text-primary'>
                                <span className="relative flex h-2.5 w-2.5 flex flex-col items-center justify-center">
                                    <span
                                        className="animate-ping  absolute inline-flex h-full w-full rounded-full bg-primary-200 opacity-75"></span>
                                    <span className="relative inline-flex rounded-full h-2 w-2 bg-primary"></span>
                                </span>
                                        <p className='font-bold'>
                                            Pending Approval
                                        </p>
                                    </div>
                                    :
                                    <></>
                        }

                        {/*Mobile only*/}
                        <div className='flex flex-row items-center gap-4 text-xs md:text-sm mt-2 md:hidden'>
                            <div className='flex flex-row items-center gap-0.5'>
                                <SvgCalendar className={'w-4 h-4 mr-1'}/>
                                <p className='inline-flex'>
                                    {`${DateTime.fromISO(event.start_timestamp).toFormat("LLL d")}${dateOrdinal(DateTime.fromISO(event.start_timestamp))}`}
                                </p>
                            </div>
                            <div className='flex flex-row items-center gap-0.5'>
                                <SvgClock className={'w-4 h-4 mr-1'}/>
                                <p className='inline-flex'>
                                    {`${DateTime.fromISO(event.start_timestamp).toFormat("hh:mm a")} - ${DateTime.fromISO(event.end_timestamp).toFormat("hh:mm a")}`}
                                </p>
                            </div>
                        </div>

                    </div>

                    {isAttending ?
                        <div className='w-full flex flex-col gap-3 md:w-1/2 mx-auto text-center mt-5'>
                            <SvgCheckCircle className={'mx-auto text-green-500 w-12 h-12'}/>
                            <div className='flex flex-col items-center text-center'>
                                <p className='mb-0'>
                                    You’re confirmed on the Guest List
                                </p>
                                <p className='font-semibold text-lg'>
                                    {userGuestObject?.profile?.first_name} {userGuestObject?.profile?.last_name}
                                </p>
                                <GroupTag group={userGuestObject?.group}/>
                            </div>
                            <div className='w-full flex flex-col gap-5'>
                                <div>
                                    <Button variant='black' disabled={!downloadAvailable} className='w-full'
                                            onClick={toggleQrCodeVisible}>
                                        Get Digital Wristband
                                    </Button>
                                </div>
                                {!downloadAvailable &&
                                    <p className='text-xs opacity-50 mt-3'>
                                        Your wristband will be available 2h prior to the event
                                    </p>
                                }
                            </div>
                            {!!props.event.guest_scopes.plusOne && userGuestObject.is_primary_guest &&
                                <div
                                    className='bg-cyan-50 border border-dashed border-cyan-500 p-3 mt-2 rounded-2xl flex flex-col -mx-2'>
                                    {!downloadAvailable && 
                                    <>
                                        {userGuestObject?.invite_limit > 0 ?
                                            <>
                                                <p className='text-lg font-bold text-cyan-500'>
                                                    Send +1s
                                                </p>
                                                <p className='text-cyan-500 mb-2'>
                                                    Copy the link or use the button below to send +1s. You
                                                    have {userGuestObject?.invite_limit} to
                                                    send.
                                                </p>
                                                <CopyLinkButton mobile={props.mobile} shortLink={shortLink}/>
                                                <p className="mt-2">OR</p>
                                                <Button className={'mt-3'} onClick={openInvitePlusOneModal}>
                                                    Send a +1
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <p className='text-lg font-bold text-cyan-500'>
                                                    +1 Requests
                                                </p>
                                                <p className='text-cyan-500'>
                                                    {/* /!*Todo: make limit here dynamic*!/ */}
                                                    Use the link below to request +1s. Each requires individual confirmation
                                                    from the Host.
                                                </p>
                                                <Button className={'mt-3'} onClick={openInvitePlusOneModal}>
                                                    Request a +1
                                                </Button>
                                            </>
                                        }
                                    </>
                                    }
                                    {guestInvites.length > 0 &&
                                        <div>
                                            <p className='mt-4 text-lg font-bold'>
                                                Your invites
                                            </p>
                                            <div className='flex flex-col'>
                                                {guestInvites.map(invite =>
                                                    <div
                                                        key={invite.contact}
                                                        className='flex flex-row items-center justify-between py-3 border-b border-black/10 last:border-none'>
                                                        <p>
                                                            {invite.contact}
                                                        </p>
                                                        <p>
                                                            {invite.status}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <div onClick={openConfirmLeaveGuestListModal} className='mt-2'>
                                <p className='text-xs text-center'>
                                    Plans change?&nbsp;
                                    <span>
                                    <span className='inline-block md:hidden'>Tap</span><span
                                        className='hidden md:inline-block'>Click</span> <span
                                        className='text-primary hover:text-primary-600 font-semibold cursor-pointer'>here</span>
                                </span>
                                    &nbsp;to be removed from the Guest List.
                                </p>
                            </div>
                        </div>
                        :
                        null
                    }
                    {hasAcceptedInviteOrRequested ?
                        <div className='w-full flex flex-col gap-3'>
                            <ListZeroState
                                image={check_back_image}
                                imageClassName={'translate-x-2 md:translate-x-4 hue-rotate-[390deg] saturate-200'}
                                title={'Check back later'}
                                description={"You’ve requested the Guest List; check back later for confirmation."}
                            />
                        </div>
                        :
                        null
                    }
                    {isApprovedToAttend ?
                        <GuestAcceptorDeclineInviteView
                            isGuestPlusOne={isGuestPlusOne}
                            myHostId={myHostId}
                            userObject={isGuestPlusOne ? myParentGuestObject : myHostObject}
                            updateGuestStatus={updateGuestStatus}
                            openConfirmRejectInviteModal={openConfirmRejectInviteModal}
                        />
                        :
                        null
                    }
                    {!isApprovedToAttend && !hasAcceptedInviteOrRequested && !isAttending && !isNotAttending &&
                        <div className='w-full flex flex-col gap-3 md:w-1/2 mx-auto text-center mt-5'>
                            <div>
                                <ListZeroState
                                    image={request_needed_image}
                                    imageClassName={'translate-x-2 md:translate-x-4'}
                                    title={"You're not on the list yet"}
                                    description={isGuestPlusOne && !!myParentGuestObject?.profile.first_name ?
                                        `${myParentGuestObject?.profile.first_name} ${myParentGuestObject?.profile.last_name} is requesting you as a +1 for this event; the Host must confirm prior to your addition to the Guest List. The Host will be able to see some of your details.`
                                        : !!myHostId ?
                                            `You’ve been invited by ${myHostObject?.profile.display_name || `${myHostObject?.profile.first_name} ${myHostObject?.profile.last_name}`} to this event. ${props.mobile ? "Tap" : "Click"} below to confirm your
                                                                spot on the Guest List.`
                                            :
                                            "You must request a Guest List spot below. Please follow the steps for your submission."
                                    }
                                />
                            </div>
                            <Button variant={'black'}
                                    onClick={openRequestToAttendModal}>
                                {'Request Guest List'}
                            </Button>
                        </div>
                    }
                    {isNotAttending &&
                        <div className='w-full flex flex-col gap-3 md:w-1/2 mx-auto text-center mt-5'>
                            <div>
                                <ListZeroState
                                    image={not_going_image}
                                    imageClassName={'translate-x-2 md:translate-x-4'}
                                    title={"You're not going"}
                                    description={"You have declined your invite to this event. Please reach out to the organizer if you believe this is incorrect."}
                                />
                            </div>
                        </div>
                    }
                </div>

                {/*Mobile only*/}
                <div className='border-t border-gray-100 pt-6 flex md:hidden'>
                    <GuestEventPageHeader event={event} hideLocation={!isApprovedToAttend && !isAttending}/>
                </div>

            </div>
            <RequestToAttendModal
                isOpen={isRequestToAttendModalOpen}
                close={closeRequestToAttendModal}
                submit={requestAccess}
                event={event}
            />
            <ConfirmationModal
                isOpen={confirmRejectInviteModalOpen}
                title={'Are you sure you want to decline this invite?'}
                description={"Confirm below to reject. "}
                confirmText={'Yes, decline this invite'}
                confirm2Text={'Yes, decline and block this host'}
                cancelText={'Cancel'}
                onConfirm={() => updateGuestStatus('Not Attending')}
                onConfirm2={declineAndBlock}
                close={closeConfirmRejectInviteModal}
            />
            <ConfirmationModal
                isOpen={confirmLeaveGuestListModalOpen}
                title={'Are you sure you want to remove yourself from the guest list?'}
                confirmText={'Yes'}
                cancelText={'Cancel'}
                onConfirm={() => updateGuestStatus('Not Attending')}
                close={closeConfirmLeaveGuestListModal}
            />
            <InvitePlusOneModal
                eventId={eventId}
                hostId={myHostId}
                inviteLimit={userGuestObject?.invite_limit}
                getEvent={getEvent}
                profileId={props.authSession.user.id}
                isOpen={isInvitePlusOneModalOpen}
                close={closeInvitePlusOneModal}
            />
            <QrCodeModal
                isOpen={!!downloadAvailable && qrCodeVisible}
                eventId={eventId}
                eventTitle={event?.title}
                eventStart={event?.start_timestamp}
                guestFirstName={userGuestObject?.profile?.first_name || ''}
                guestLastName={userGuestObject?.profile?.last_name || ''}
                group={userGuestObject?.group}
                profileId={profileId}
                close={toggleQrCodeVisible}
            />
            <AcceptedInviteSuccessModal
                isOpen={isAcceptedSuccessModalVisible}
                close={closeSuccessModal}
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestEventPage);
